const initialState = {
  isLoading: false,
  components: [],
  violations: [],
  tempEnvYml: null,
  steps: {
    step1: 'active',
    step2: '',
    step3: '',
  },
  componentsYaml: '',
  errorMessage: null,
  isEditLoading: false,
  configErrorMessage: null,
  variables: null,
  isSearch: false,
  applicationComponent: {},
};

export default initialState;
