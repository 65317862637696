const actionTypes = {
  GET_COMPONENTS: 'GET_COMPONENTS',
  GO_BACK: 'GO_BACK',
  STORE_APPLICATION_COMPONENT: 'STORE_APPLICATION_COMPONENT',
  SAVE_COMPONENT: 'SAVE_COMPONENT',
  SAVE_TEMP_COMPONENT: 'SAVE_TEMP_COMPONENT',
  DELETE_COMPONENT: 'DELETE_COMPONENT',
  DELETE_TEMP_COMPONENT: 'DELETE_TEMP_COMPONENT',
  REVIEW_COMPONENTS: 'REVIEW_COMPONENTS',
  CREATE_APPLICATION: 'CREATE_APPLICATION',
  PUBLISH_APPLICATION: 'PUBLISH_APPLICATION',
  SET_CONFIG_ERROR: 'SET_CONFIG_ERROR',
  GET_APPLICATION_VARIABLES: 'GET_APPLICATION_VARIABLES',
  CREATE_APPLICATION_VARIABLE: 'CREATE_APPLICATION_VARIABLE',
  EDIT_APPLICATION_VARIABLE: 'EDIT_APPLICATION_VARIABLE',
  DELETE_APPLICATION_VARIABLE: 'DELETE_APPLICATION_VARIABLE',
  RESET_STATE: 'RESET_STATE',
  RESET_APP_ERRORS: 'RESET_APP_ERRORS',
};

export default actionTypes;
