import * as R from 'ramda';
import mixpanel from 'mixpanel-browser';
import { includes, getErrorCode, isStatus, getErrorMessage } from 'utils';
import userManager from 'modules/userManager';
import { createNotification } from 'components/notificationsContainer/helpers';
import { genericError, ERROR_CODES, networkError, deniedError, unauthorizedError, notFoundError } from './helpers';

let lastServiceMaintenanceTimestamp = 0;

const isTokenInvalid = (error) => {
  const isRefreshPath = includes(R.path(['config', 'url'], error), 'token/refresh');
  const errorCode = getErrorMessage(error);
  return isStatus(401, error) && !isRefreshPath && errorCode === ERROR_CODES.UNAUTHORIZED_ACCESS;
};

const refreshInterceptor = (error, logOut) => {
  const originalRequest = error.config;

  if (isTokenInvalid(error) && !originalRequest.retry) {
    userManager.logout(logOut);
    window.location.href = '/login';
  }
  return Promise.reject(error);
};

const isMaintenanceSystemUnavailableError = (errorCode, errorResponse) => {
    if (errorCode !== 593) {
      return false;
    }

    const errorMessage = errorResponse?.data?.detail || '';

    return errorMessage.startsWith('[MAINTENANCE] ');
}

export const errorHandler = (error, logOut, handleErrorScreen) => {
  /** 
   [!] by default, the error screen will not be displayed if an error occurs, 
   unless the 'alwaysShowCriticalErrors' argument was set during initialization. 
   */

  const errorCode = getErrorCode(error) || error.response?.status;
  const isOffLine = window?.navigator?.onLine === false;
  const { alwaysShowCriticalErrors: readyToTriggerErrorScreen } = error.config;
  const isNetworkErrorMessage = error.message === ERROR_CODES.NETWORK_ERROR_MESSAGE;
  const urlNotInExceptions =
    !includes(window.location.pathname, '/applications/create-from-docker-compose') &&
    !includes(window.location.pathname, '/configuration') &&
    !includes(R.path(['config', 'url'], error), 'component-pipeline-logs') &&
    !includes(R.path(['config', 'url'], error), '/components') &&
    !includes(R.path(['config', 'url'], error), '/verify') &&
    !includes(R.path(['config', 'url'], error), '/streamer');

  if (errorCode === ERROR_CODES.UNAUTHORIZED_ACCESS) {
    mixpanel.reset();
    return userManager.logout(logOut);
  }

  /**
   * user is Online => 500+1 errors (503 service unavailable etc)
   * */
  if (isNetworkErrorMessage && urlNotInExceptions && errorCode && !isOffLine) {
    /* show error screen */
    if (!includes(window.location.pathname, '/applications/create-from-docker-compose')) {
      genericError.title = `${errorCode ? `${errorCode} - ` : '500 - '}Internal Server Error`;
      handleErrorScreen({ isActive: true, details: genericError });
    }

    return error;
  }

  /* show corresponding error screen  */
  if (errorCode === 401) {
    handleErrorScreen({ isActive: true, details: unauthorizedError });
  } else if (errorCode === 403) {
    handleErrorScreen({ isActive: true, details: deniedError });
  } else if (errorCode === 404 && readyToTriggerErrorScreen && urlNotInExceptions) {
    handleErrorScreen({ isActive: true, details: notFoundError });
  } else if (errorCode === 500 && readyToTriggerErrorScreen && urlNotInExceptions) {
    handleErrorScreen({ isActive: true, details: genericError });
  } else if (isMaintenanceSystemUnavailableError(errorCode, error.response)) {

    if (new Date().getTime() - 5000 > lastServiceMaintenanceTimestamp) {
      let errorMessage = 'System is unavailable now, please try again in a moment';
      if (error.response?.data?.detail) {
        errorMessage = error.response.data.detail.replace('[MAINTENANCE] ', '');
      }

      createNotification('error', 'Maintenance notice', errorMessage);

      lastServiceMaintenanceTimestamp = new Date().getTime();
    }

  } else if (error.message === ERROR_CODES.NETWORK_ERROR_MESSAGE && isOffLine) {
  /** OFFLINE screen */
    handleErrorScreen({ isActive: true, details: networkError });
  }

  return refreshInterceptor(error, logOut);
};

export default refreshInterceptor;
